<template>
  <ContentCard id="available-subscription" title="Subscriptions">
    <PlanInfo id="available-plan-info" />
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import ContentCard from '@/Common/ContentCard'
import PlanInfo from '@/Pages/Subscription/Components/PlanInfo'

export default {
  name: 'AvailableSubscriptions',
  components: {
    ContentCard,
    PlanInfo,
  },
}
</script>

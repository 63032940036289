<template>
  <ContentCard
    id="current-subscription"
    title="Current Subscriptions"
    :highlight="true"
  >
    <template #extras>
      <aside v-if="trial">
        <span class="font-semibold text-black inline-block mr-1"
          >Trial Ends</span
        >
        <span class="text-gray-dark">{{ trialEndDateNumerical }}</span>
      </aside>
    </template>
    <TrialInfo
      v-if="trial && !subscribed"
      expires
      estimatedCost
      id="current-trial-info"
    />
    <PlanInfo
      v-if="subscribed"
      :subscribed="subscribed"
      id="current-plan-info"
    />
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapGetters } from 'vuex'
import ContentCard from '@/Common/ContentCard'
import TrialInfo from '@/Pages/Subscription/Components/TrialInfo'
import PlanInfo from '@/Pages/Subscription/Components/PlanInfo'
export default {
  name: 'CurrentSubscription',
  components: {
    ContentCard,
    TrialInfo,
    PlanInfo,
  },
  props: {
    trial: Boolean,
    subscribed: Boolean,
  },
  computed: {
    ...mapGetters('billing', ['trialPeriodEnds']),
    trialEndDateNumerical: function () {
      return moment(this.trialPeriodEnds).format('L')
    },
  },
}
</script>

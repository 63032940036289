<template>
  <div id="trial-info" class="px-4 py-8">
    <h3 class="mb-2">Pay-As-You-Go Free Trail</h3>
    <p class="text-gray-dark text-sm">Expires {{ trialEndDateWrittenOut }}</p>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'TrialInfo',
  props: {
    expires: String,
    estimatedCost: String,
  },
  computed: {
    ...mapGetters('billing', ['trialPeriodEnds']),
    trialEndDateWrittenOut: function () {
      return moment(this.trialPeriodEnds).format('LL')
    },
  },
}
</script>

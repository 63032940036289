<template>
  <div id="plan-info" class="flex justify-between items-center px-4 pt-8 pb-6">
    <div class="flex">
      <div
        class="w-80 bg-tozny flex flex-col items-center justify-center mr-6 flex-shrink-0"
      >
        <img class="mb-2" src="@/assets/plan-logo.svg" />
        <div class="text-white text-lg font-bold">Pay-As-You-Go</div>
      </div>
      <div class="flex flex-col justify-between pr-16">
        <p class="text-black font-body text-base mb-4 leading-snug">
          Flexible plan that requires no upfront costs or commitment. You pay
          only for what you use.
        </p>
        <div class="mb-0">
          <strong
            class="helvetica text-base text-black font-medium mt-1 leading-tight inline-block mb-2"
            >Plan Includes</strong
          >
          <ul class="text-gray-dark text-body text-base leading-snug">
            <li class="flex mb-2">
              <i class="material-icons text-tozny micon mr-2 flex-shrink-0"
                >done</i
              >
              TozStore
            </li>
            <li class="flex mb-2">
              <i class="material-icons text-tozny micon mr-2 flex-shrink-0"
                >done</i
              >
              TozID
            </li>
            <li class="flex mb-2">
              <i class="material-icons text-tozny micon mr-2 flex-shrink-0"
                >done</i
              >
              Pay only what you use
            </li>
            <li class="flex">
              <i class="material-icons text-tozny micon mr-2 flex-shrink-0"
                >done</i
              >
              Access to all future Tozny products
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex-shrink-0">
      <ToznyButton
        v-if="subscribed"
        class="mb-4 bg-white text-gray-darkest border border-gray-medium px-8 font-semibold text-center leading-snug rounded"
        buttonText="Pause Plan"
        @click.native="unsubscribe"
        id="pause-plan-button"
      />
      <div v-else class="mb-4 flex flex-col items-center">
        <a
          class="no-underline"
          href="https://tozny.com/contact-sales"
          target="_blank"
        >
          <ToznyButton
            class="contact-sales-button bg-tozstore hover:bg-tozstore mb-2 rounded"
            id="contact-sales-button"
            buttonText="Contact Sales"
          />
        </a>
        <ToznyButton
          id="upgrade-plan-button"
          class="upgrade-button mb-1 rounded"
          buttonText="Upgrade Plan"
          @click.native="trySubscribe"
        />
        <a
          href="https://tozny.com/pricing/"
          target="_blank"
          class="font-14 no-underline text-gray-darkest"
          >Learn more</a
        >
      </div>
      <!-- This is a good candidate for a popover / tooltip. -->
      <p v-if="addCardNotice" id="add-card-notice">
        Add billing card to start.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'
import ToznyButton from '@/Common/ToznyButton'
export default {
  name: 'PlanInfo',
  components: {
    ToznyButton,
  },
  data: function () {
    return {
      addCardNotice: false,
    }
  },
  computed: {
    ...mapGetters('account', ['payingCustomer']),
  },
  props: {
    subscribed: Boolean,
  },
  methods: {
    ...mapActions('billing', ['subscribe', 'unsubscribe']),
    trySubscribe: function () {
      this.addCardNotice = false
      if (!this.payingCustomer) {
        this.addCardNotice = true
      } else {
        return this.subscribe()
      }
    },
  },
}
</script>

<style scoped>
.font-14 {
  font-size: 14px;
}
</style>

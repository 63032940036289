<template>
  <ContentCard id="billing" title="Billing" class="pb-2">
    <ToznyForm class="p-4" :error="displayError" :onSubmit="pay">
      <label for="stripe-card" class="pt-4">Card Number</label>
      <div v-if="showCardRepresentation" id="card-representation" class="mb-8">
        <input
          id="card-representation-input"
          v-on:click="clickForUpdate = true"
          :placeholder="cardRepresentationPlaceholder"
        />
      </div>
      <Card
        v-if="stripePK && !showCardRepresentation"
        class="stripe-card p-4 mt-2 mb-8 border border-gray-medium font-display1 placeholder-black"
        :stripe="stripePK"
        :options="stripeOptions"
        id="stripe-card-element"
      />
      <ToznyButton
        id="update-billing-button"
        class="mb-4"
        :class="{
          'px-6 ': !updateBillingLoading,
          'px-16 ': updateBillingLoading,
        }"
        buttonText="Update Billing"
        :loading="updateBillingLoading"
      />
    </ToznyForm>
  </ContentCard>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Card, createToken } from 'vue-stripe-elements-plus'
import ContentCard from '@/Common/ContentCard'
import ToznyButton from '@/Common/ToznyButton'
import ToznyForm from '@/Common/ToznyForm'

export default {
  components: {
    Card,
    ContentCard,
    ToznyButton,
    ToznyForm,
  },
  data() {
    return {
      clickForUpdate: false,
      stripeElementError: '',
      stripePK: null,
      complete: false,
      stripeOptions: {
        elements: {
          fonts: [
            { cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans' },
          ],
        },

        iconStyle: 'default',
        style: {
          base: {
            // Add your base input styles here. For example:
            fontSize: '16px',
            // Font seems mismatched even though it shows Open Sans in editor.
            fontFamily: 'Open Sans',
            '::placeholder': {
              fontFamily: 'Open Sans',
              color: '#CBD0D5',
            },
            iconColor: '#CBD0D5',
          },
        },
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      placeholder: 'xxxx-xxxx-xxxx-xxxx',
    }
  },
  computed: {
    ...mapState('account', [
      'updateBillingLoading',
      'updateBillingErrorMessage',
    ]),
    ...mapGetters('account', ['billingInfo', 'payingCustomer']),
    displayError: function () {
      return this.stripeElementError
        ? this.stripeElementError
        : this.updateBillingErrorMessage
    },
    cardRepresentationPlaceholder: function () {
      if (this.billingInfo.cc_last4) {
        return `${this.billingInfo.cc_brand}   XXXX-XXXX-XXXX-${this.billingInfo.cc_last4}               ${this.billingInfo.cc_exp_month}/${this.billingInfo.cc_exp_year}       ${this.billingInfo.zip}`
      }
      return ''
    },
    showCardRepresentation: function () {
      // return 'something'
      return this.payingCustomer && !this.clickForUpdate
    },
  },
  mounted: function () {
    // eslint-disable-next-line
    this.stripePK = STRIPE_PK
  },
  methods: {
    ...mapActions('account', ['updateAccountBilling']),
    ...mapActions('billing', ['checkValidBilling']),
    async pay() {
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      this.stripeElementError = ''
      const tokenResponse = await createToken()
      if (tokenResponse.token) {
        const stripeToken = tokenResponse.token
        const billingUpdated = await this.updateAccountBilling(stripeToken)
        if (billingUpdated) {
          await this.checkValidBilling()
        }
        this.clickForUpdate = false
      } else {
        this.stripeElementError = tokenResponse.error.message
      }
    },
  },
}
</script>

<style>
.stripe-card input ::placeholder {
  color: red;
}
.stripe-card.complete {
  border-color: green;
}
</style>

<template>
  <ContentCard id="coupon" title="Coupon">
    <ToznyForm
      class="p-4 mt-4"
      :error="couponErrorMessage"
      :success="couponSuccessMessage"
      :onSubmit="applyCoupon"
    >
      <ToznyInput
        v-model="couponCode"
        id="couponCode"
        name="couponCode"
        label="Coupon Code"
        class="mb-8"
      />
      <ToznyButton
        class="mb-4"
        :class="{ 'px-14': !couponLoading, 'px-20': couponLoading }"
        buttonText="Submit"
        :loading="couponLoading"
      />
    </ToznyForm>
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import ToznyButton from '@/Common/ToznyButton'
import ContentCard from '@/Common/ContentCard'
export default {
  name: 'Coupon',
  components: {
    ToznyForm,
    ToznyInput,
    ToznyButton,
    ContentCard,
  },
  data: function () {
    return {
      couponCode: '',
    }
  },
  computed: {
    ...mapState('billing', [
      'couponSuccessMessage',
      'couponErrorMessage',
      'couponLoading',
    ]),
  },
  methods: {
    ...mapActions('billing', ['applyCouponCode']),
    applyCoupon: function () {
      this.applyCouponCode(this.couponCode)
    },
  },
}
</script>

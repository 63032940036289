<template>
  <MainLayout>
    <CurrentSubscription
      v-if="isTrial || (payingCustomer && accountActive)"
      :trial="isTrial"
      :subscribed="payingCustomer"
    />
    <AvailableSubscriptions v-if="!payingCustomer || !accountActive" />
    <Billing />
    <Coupon />
  </MainLayout>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'

import AvailableSubscriptions from '@/Pages/Subscription/Components/AvailableSubscriptions'
import Coupon from './Components/Coupon'
import CurrentSubscription from '@/Pages/Subscription/Components/CurrentSubscription'
import MainLayout from '@/Common/MainLayout/MainLayout'
import Billing from './Components/Billing'

export default {
  name: 'Subscription',
  components: {
    AvailableSubscriptions,
    Billing,
    Coupon,
    CurrentSubscription,
    MainLayout,
  },
  computed: {
    ...mapGetters('billing', ['isTrial', 'accountActive']),
    ...mapGetters('account', ['payingCustomer']),
  },
}
</script>
